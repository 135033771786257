import { makeStyles, Theme } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/ErrorOutline';
import WarningIcon from '@material-ui/icons/ReportProblemOutlined';
import React from 'react';

import { Colors } from 'src/common/ui/base';

const STATUS_TO_PROPS = {
  'on track to complete period requirements': {
    label: 'On Track',
    borderColor: Colors.Blue3,
    color: Colors.CoolGray8,
    backgroundColor: 'transparent',
    icon: CheckIcon,
  },
  'period requirements complete': {
    label: 'Period req complete',
    borderColor: Colors.Blue3,
    color: Colors.CoolGray8,
    backgroundColor: 'transparent',
    icon: CheckIcon,
  },
  'at risk of regulatory pause': {
    label: 'At risk',
    borderColor: '#6B5000',
    color: '#6B5000',
    backgroundColor: Colors.Yellow1,
    icon: WarningIcon,
  },
  'buprenorphine negative protocol': {
    label: 'Buprenorphine negative protocol',
    borderColor: '#6B5000',
    color: '#6B5000',
    backgroundColor: Colors.Yellow1,
    icon: WarningIcon,
  },
  'on regulatory pause': {
    label: 'Regulatory req incomplete',
    borderColor: '#9E0012',
    color: '#9E0012',
    backgroundColor: '#F9E6E8',
    icon: ErrorIcon,
  },
};

const defaultProps = {
  label: undefined, // show status string if not recognized
  borderColor: Colors.CoolGray8,
  color: Colors.CoolGray8,
  backgroundColor: 'transparent',
  icon: CheckIcon,
};

export const DrugScreenStatusIcon = ({ status = '', link }) => {
  const {
    label,
    borderColor,
    color,
    backgroundColor,
    icon: Icon,
  } = STATUS_TO_PROPS[status] || defaultProps;

  const styles = useStyles({
    color,
    backgroundColor,
    borderColor,
  });

  return (
    <div
      className={styles.container}
      onClick={() => {
        if (link) window.location.href = link;
      }}
    >
      <Icon color={color} />
      {label || status}
    </div>
  );
};

const useStyles = makeStyles<
  Theme,
  { color?: string; backgroundColor?: string; borderColor?: string }
>(() => ({
  container: {
    border: ({ borderColor }) => `1px solid ${borderColor}`,
    background: ({ backgroundColor }) => backgroundColor,
    color: ({ color }) => color,
    borderRadius: '4px',
    display: 'flex',
    padding: '4px',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '4px',
    cursor: 'pointer',
  },
}));
