import { useContext, useMemo } from 'react';

import FeatureFlagContext from 'src/components/featureflags/featureFlagContext';
import useTypedSWR from 'src/components/general/useTypedSWR';
import { EVENT_LOCATION_VERIFICATION } from 'src/events/hooks/useEventLocationVerification.gql';
import { LOCATION_VERIFICATION } from 'src/featureFlags/currentFlags';

interface EventLocationVerificationResult {
  actualState: string;
  expectedState: string;
}

interface EventLocationVerification {
  isVerified: boolean;
  verifiedLocation: string;
}

export const useEventLocationVerification = (
  eventId?: string | null,
): EventLocationVerification | undefined => {
  const flags = useContext(FeatureFlagContext);
  const { data } = useTypedSWR(
    eventId && flags[LOCATION_VERIFICATION] ? [EVENT_LOCATION_VERIFICATION, { eventId }] : null,
  );

  const { actualState, expectedState } =
    (data?.staff_getLocationVerificationForEvent as EventLocationVerificationResult) || {};

  const isVerified = useMemo(() => {
    if (!eventId) return false;
    if (!actualState || !expectedState) return false;
    return actualState === expectedState;
  }, [actualState, expectedState, eventId]);

  if (!eventId || !flags[LOCATION_VERIFICATION]) return undefined;

  return { isVerified, verifiedLocation: actualState };
};
