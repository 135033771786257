/**
 * Where a given patient is currently at with their drug screen requirements
 *
 * Changes relatively frequently on a patient's chart, as time crosses into new periods
 * and screening is completed (or not).
 *
 * Source of truth for the values is the `dspStatus` patient chart property in Dato.
 */
export enum DspStatus {
  /**
   * Period requirements complete
   *
   * The patient has completed screening for the current period.
   */
  Complete = 'complete',
  /**
   * On track to complete period requirements
   *
   * The patient hasn't done the screening yet, but has plenty of time still.
   */
  OnTrack = 'on track',
  /**
   * At risk of regulatory pause
   *
   * Screening hasn't happened for this period, and the period is getting close to ending.
   */
  AtRisk = 'at risk',
  /**
   * On regulatory pause
   *
   * We've had to pause treatment because screening was not completed on schedule.
   */
  RegulatoryPause = 'regulatory pause',

  /**
   * Buprenorphine negative protocol
   *
   * The patient is on the buprenorphine negative protocol
   */
  BuprenorphineNegativeProtocol = 'bup negative',
}
