import { addMinutes, endOfDay, isBefore, subMilliseconds } from 'date-fns';
import { formatInTimeZone, getTimezoneOffset } from 'date-fns-tz';
// @types/lodash doesn't have discrete packages, but `import type` doesn't impact bundle size
// eslint-disable-next-line no-restricted-imports
import type { Dictionary } from 'lodash';
import pick from 'lodash/pick';
import pickBy from 'lodash/pickBy';

import type { EventType, SubType } from 'src/events/visitTypes';
import { ProviderRole } from 'src/stores/users/userType';
import { parseUnknownDate } from 'src/util/parseUnknownDate';

/**
 * A type representing a mobx-state-tree Event
 * object as defined in stores/resource.ts. We
 * don't import that type here in order to avoid
 * a circular reference.
 */
type Event = {
  start: Date | null;

  /**
   * Duration of the event in minutes
   */
  duration: number | null;

  title: string | null;
  type: string | null;
  subType: string | null;
  status: string | null;
};

/**
 * A type representing an event "item" in
 * the staff app's calendar.
 */
type CalendarItem = {
  allDay: boolean;
  duration: number | null;
  start: Date | string;
  timezone?: string;
};

// TODO: Copy-pasted with boulder-server's events code
export const EVENT_STATUSES = {
  SCHEDULED: 'scheduled',
  RESCHEDULED: 'rescheduled',
  CONFIRMED: 'confirmed',
  CANCELED: 'canceled',
  // Ideally this should be past tense for consistency, but there is already data with this
  COMPLETED: 'complete',
};

// Events with the following statuses cannot be edited without a status change
export const UNEDITABLE_EVENT_STATUSES = [
  EVENT_STATUSES.CANCELED,
  EVENT_STATUSES.RESCHEDULED,
  EVENT_STATUSES.COMPLETED,
];

// Events with the following statuses are not expected to happen
export const UNSCHEDULED_EVENT_STATUSES = [EVENT_STATUSES.CANCELED, EVENT_STATUSES.RESCHEDULED];

export const EVENT_COLORS = {
  LIGHT_GREEN: '#9DD3AE',
  GREEN: '#61A97E',
  LIGHT_GRAY: '#C1C1C1',
  GRAY: '#888888',
  WHITE: '#FFFFFF',
  PEACH: '#FEAD77',
  BLUE: '#646AB1',
  BEIGE: '#F0E5DD',
  LIGHT_BEIGE: '#FAF2EB',
};

type EventTypeDefinition = {
  label: string;
  disabled?: boolean;
};

type EventTypes = { [name in EventType]: EventTypeDefinition };

// Note these are duplicated in boulder-server's events code
export const TYPES: EventTypes = {
  lab: { label: 'Lab', disabled: true },
  appointment_virtual: { label: 'Virtual Visit' },
  appointment_inperson: { label: 'In-Person Visit', disabled: true },
  availability: { label: 'Available' },
  other: { label: 'Other' },
};

type EventSubType = {
  mandatorySections: string[];
} & EventTypeDefinition;

export type EventSubTypes = { [name in SubType]: EventSubType };

const SUB_TYPES: EventSubTypes = {
  general: {
    label: 'General Visit',
    mandatorySections: ['general'],
  },
  staff_meeting: {
    label: 'Staff Meeting',
    mandatorySections: ['general'],
    disabled: true,
  },
  prescriber_followup: {
    label: 'Clinician: Follow Up',
    mandatorySections: [
      'prescriberVisitPrep',
      'prescriberTelehealthPrecheck',
      'prescriberUpdatePatientHistory',
      'prescriberSubjectiveFollowUp',
      'prescriberObjective',
      'prescriberAssessmentFollowUp',
      'prescriberPlanFollowUp',
      'telehealthWrapUp',
    ],
  },
  prescriber_initial: {
    label: 'Clinician: Intake',
    mandatorySections: [
      'prescriberVisitPrep',
      'prescriberTelehealthPrecheck',
      'subjectiveInitial',
      'prescriberObjective',
      'dsm5OudAssessment',
      'asamAssessment',
      'prescriberAssessmentFollowUp',
      'prescriberPlanInitial',
      'prescriberInitialVisitWrapUp',
      'telehealthWrapUp',
      'patientSafetyScreener',
    ],
  },
  prescriber_note: {
    label: 'Clinician: Note',
    mandatorySections: [],
  },
  prescriber_reengagement_visit: {
    label: 'Clinician: Reengagement',
    mandatorySections: [
      'prescriberVisitPrep',
      'prescriberTelehealthPrecheck',
      'prescriberUpdatePatientHistory',
      'prescriberSubjectiveReengagement',
      'prescriberObjective',
      'prescriberAssessmentFollowUp',
      'prescriberPlanReengagement',
      'telehealthWrapUp',
    ],
  },
  prescriber_paneling_followup: {
    label: 'Clinician: Panel Transfer',
    mandatorySections: [
      'prescriberVisitPrep',
      'prescriberTelehealthPrecheck',
      'prescriberUpdatePatientHistory',
      'prescriberSubjectiveFollowUp',
      'prescriberObjective',
      'prescriberAssessmentFollowUp',
      'prescriberPlanFollowUp',
      'telehealthWrapUp',
    ],
  },
  prescriber_bridge_care: {
    label: 'Clinician: Team Follow Up',
    mandatorySections: [
      'prescriberVisitPrep',
      'prescriberTelehealthPrecheck',
      'prescriberUpdatePatientHistory',
      'prescriberSubjectiveFollowUp',
      'prescriberObjective',
      'prescriberAssessmentFollowUp',
      'prescriberPlanFollowUp',
      'telehealthWrapUp',
    ],
  },
  prescriber_drop_in_followup: {
    label: 'Clinician: Pop-In Follow Up',
    mandatorySections: [],
  },
  prescriber_drop_in_intake: {
    label: 'Clinician: Pop-In Intake',
    mandatorySections: [],
  },
  prescriber_drop_in_reengagement: {
    label: 'Clinician: Pop-In Reengagement',
    mandatorySections: [],
    disabled: true,
  },
  prescriber_mental_health_assessment: {
    label: 'Clinician: Mental Health Assessment',
    mandatorySections: [],
  },
  peer_coach_visit: {
    label: 'Peer Recovery Specialist Visit',
    mandatorySections: ['peerCoachTelehealthPrecheck', 'peerCoachVisit', 'telehealthWrapUp'],
    disabled: true,
  },
  peer_coach_drop_in_visit: {
    label: 'Peer Recovery Specialist: Pop-In Visit',
    mandatorySections: [],
    // Disabled in the sense that these visits cannot be seen
    // in the create-event modal, but they are in use and created
    // automatically via the drop-in clinic system
    disabled: true,
  },
  peer_followup: {
    label: 'Peer Recovery Specialist: Follow Up',
    mandatorySections: [],
  },
  peer_intake: {
    label: 'Peer Recovery Specialist: Intake',
    mandatorySections: [],
  },
  peer_recovery_group: {
    label: 'Peer Recovery Group',
    mandatorySections: [],
  },
  peer_note: {
    label: 'Peer Recovery Specialist: Note',
    mandatorySections: [],
  },
  mam_visit: {
    label: 'Care Advocate: MAM',
    mandatorySections: [
      'careAdvocateTelehealthPrecheck',
      'careAdvocatePatientCheckIn',
      'careAdvocateSynchronousMam',
    ],
  },
  oft_visit: {
    label: 'Care Advocate: OFT',
    mandatorySections: [
      'careAdvocateTelehealthPrecheck',
      'careAdvocatePatientCheckIn',
      'careAdvocateSynchronousOft',
      'careAdvocateOftResults',
    ],
  },
  rcv: {
    label: 'RCV',
    mandatorySections: [
      'prescriberVisitPrep',
      'prescriberTelehealthPrecheck',
      'onboardingReferralDetails',
      'subjectiveInitial',
      'dsm5OudAssessment',
      'prescriberPlanInitial',
      'onboardingSocialNeeds',
      'onboardingRcvWrapUp',
    ],
    disabled: true,
  },
  video_test: {
    label: 'Video Test',
    mandatorySections: ['notes'],
  },
  inperson_general: {
    label: 'In-Person General Visit',
    mandatorySections: ['general'],
  },
  note: {
    label: 'Note',
    mandatorySections: ['notes'],
  },
  onboarding_checklist: {
    label: 'Onboarding Checklist',
    mandatorySections: ['onboardingChecklist'],
    disabled: true,
  },
  phone_call: {
    label: 'Phone Call',
    mandatorySections: ['notes'],
  },
  rcv_setup: {
    label: 'RCV Setup',
    mandatorySections: [
      'onboardingRcvSetupIntro',
      'onboardingSocialNeeds',
      'onboardingRcvSetupDetails',
    ],
    disabled: true,
  },
  inquiry_potential_patient: {
    label: 'Inquiry: Potential Patient',
    mandatorySections: ['inquiryCallDetails', 'inquiryPotentialPatient'],
  },
  inquiry_current_patient: {
    label: 'Inquiry: Current Patient',
    mandatorySections: ['inquiryCallDetails', 'notes'],
    disabled: true,
  },
  inquiry_covid19_screening: {
    label: 'Inquiry: COVID-19 Screening',
    mandatorySections: ['inquiryCallDetails', 'notes'],
    disabled: true,
  },
  inquiry_referrer: {
    label: 'Inquiry: Referrer',
    mandatorySections: ['inquiryCallDetails', 'notes'],
  },
  inquiry_webform: {
    label: 'Inquiry: Webform',
    mandatorySections: ['inquiryFormDetails', 'inquiryCallDetails', 'inquiryPotentialPatient'],
  },
  inquiry_webform_call: {
    label: 'Inquiry: Webform (call)',
    mandatorySections: ['inquiryFormDetails', 'inquiryCallDetails', 'inquiryPotentialPatient'],
  },
  inquiry_inapp_call: {
    label: 'Inquiry: In-app Call Request',
    mandatorySections: ['inquiryFormDetails', 'inquiryCallDetails', 'inquiryPotentialPatient'],
  },
  inquiry_inapp_enrollment: {
    label: 'Inquiry: In-app Enrollment',
    mandatorySections: ['inquiryFormDetails', 'inquiryCallDetails', 'inquiryPotentialPatient'],
  },
  inquiry_other: {
    label: 'Inquiry: Other',
    mandatorySections: ['inquiryCallDetails', 'notes'],
  },
  prescriber_covid19_screening: {
    label: 'COVID-19 Screening',
    mandatorySections: ['prescriberTelehealthPrecheck', 'notes', 'telehealthWrapUp'],
    disabled: true,
  },
  blocked: {
    label: 'Blocked',
    mandatorySections: [],
    disabled: true,
  },
  blocked_holiday: {
    label: 'Blocked: Holiday',
    mandatorySections: [],
  },
  blocked_flex_holiday: {
    label: 'Blocked: Flex Holiday',
    mandatorySections: [],
  },
  blocked_personal: {
    label: 'Blocked: Personal',
    mandatorySections: [],
  },
  blocked_internal: {
    label: 'Blocked: Internal',
    mandatorySections: [],
    disabled: true,
  },
  blocked_admin: {
    label: 'Blocked: Admin',
    mandatorySections: [],
  },
  blocked_drop_in_admin: {
    label: 'Blocked: Pop-In Admin',
    mandatorySections: [],
  },
  blocked_meeting: {
    label: 'Blocked: Meeting',
    mandatorySections: [],
  },
  case_manager_consultation: {
    label: 'Case Manager: Consultation',
    mandatorySections: [],
  },
  case_manager_intake: {
    label: 'Case Manager: Intake',
    mandatorySections: [
      'caseManagerIntake',
      'careAdvocateTelehealthPrecheck',
      'caseManagerNote',
      'telehealthWrapUp',
    ],
  },
  case_manager_follow_up: {
    label: 'Case Manager: Follow Up',
    mandatorySections: ['careAdvocateTelehealthPrecheck', 'caseManagerNote', 'telehealthWrapUp'],
  },
  case_manager_note: {
    label: 'Case Manager: Note',
    mandatorySections: [],
  },
  case_manager_transition: {
    label: 'Case Manager: Transition',
    mandatorySections: ['caseManagementTransition'],
  },
  case_manager_drop_in_visit: {
    label: 'Case Manager: Drop In Visit',
    mandatorySections: [],
    // Disabled in the sense that these visits cannot be seen
    // in the create-event modal, but they are in use and created
    // automatically via the drop-in clinic system
    disabled: true,
  },
  care_advocate_intake: {
    label: 'Care Advocate: Intake',
    mandatorySections: ['careAdvocateTelehealthPrecheck', 'notes'],
  },
  care_advocate_drop_in_intake: {
    label: 'Care Advocate: Pop-In Intake',
    mandatorySections: [],
  },
  care_advocate_drop_in_oft: {
    label: 'Care Advocate: Pop-In OFT',
    mandatorySections: [],
  },
  hold_new_patient: {
    label: 'Hold: New Patient',
    mandatorySections: [],
  },
  hold_paneling_follow_up: {
    label: 'Hold: Paneling Follow Up',
    mandatorySections: [],
  },
  registered_nurse: {
    label: 'RN: Follow Up',
    mandatorySections: [],
  },
  registered_nurse_bridge_care: {
    label: 'RN: Bridge Care',
    mandatorySections: [],
    disabled: true,
  },
  registered_nurse_drop_in_followup: {
    label: 'RN: Pop-In Follow Up',
    mandatorySections: [],
  },
  registered_nurse_reengagement: {
    label: 'RN: Reengagement',
    mandatorySections: [],
  },
  registered_nurse_triage_note: {
    label: 'RN: Triage Note',
    mandatorySections: [],
  },
  bridge_prescription_request: {
    label: 'Bridge Prescription Request',
    mandatorySections: [],
  },
  registered_nurse_drop_in_reengagement: {
    label: 'RN: Pop-In Reengagement',
    mandatorySections: [],
    disabled: true,
  },
  google_event: {
    label: 'Google Event',
    mandatorySections: [],
  },
  discharge_summary: {
    label: 'Discharge Summary',
    mandatorySections: [],
  },
};

export const BLOCKED_EVENT_SUB_TYPES: Array<keyof EventSubTypes> = [
  'blocked',
  'blocked_admin',
  'blocked_drop_in_admin',
  'blocked_flex_holiday',
  'blocked_holiday',
  'blocked_internal',
  'blocked_meeting',
  'blocked_personal',
];

export const CLINICIAN_EVENT_SUBTYPES: Array<keyof EventSubTypes> = [
  'prescriber_bridge_care',
  'prescriber_covid19_screening',
  'prescriber_drop_in_followup',
  'prescriber_drop_in_intake',
  'prescriber_drop_in_reengagement',
  'prescriber_followup',
  'prescriber_initial',
  'prescriber_paneling_followup',
  'prescriber_reengagement_visit',
];

export const RN_EVENT_SUBTYPES: Array<keyof EventSubTypes> = [
  'registered_nurse',
  'registered_nurse_bridge_care',
  'registered_nurse_drop_in_followup',
  'registered_nurse_drop_in_reengagement',
  'registered_nurse_reengagement',
];

export const CASE_MANAGER_EVENT_SUBTYPES: Array<keyof EventSubTypes> = [
  'case_manager_consultation',
  'case_manager_drop_in_visit',
  'case_manager_follow_up',
  'case_manager_intake',
  'case_manager_transition',
];

export const INTAKE_EVENT_SUBTYPES: Array<keyof EventSubTypes> = [
  'prescriber_initial',
  'prescriber_drop_in_intake',
  'peer_intake',
  'case_manager_intake',
  'care_advocate_drop_in_intake',
  'care_advocate_intake',
];

export const FOLLOW_UP_EVENT_SUBTYPES: Array<keyof EventSubTypes> = [
  'prescriber_followup',
  'prescriber_drop_in_followup',
  'hold_paneling_follow_up',
  'prescriber_paneling_followup',
  'registered_nurse_drop_in_followup',
  'peer_followup',
  'case_manager_follow_up',
];

export const REENGAGEMENT_EVENT_SUBTYPES: Array<keyof EventSubTypes> = [
  'prescriber_reengagement_visit',
  'prescriber_drop_in_reengagement',
  'registered_nurse_reengagement',
  'registered_nurse_drop_in_reengagement',
];

export const MEDICAL_VISIT_SUBTYPES: Array<keyof EventSubTypes> =
  CLINICIAN_EVENT_SUBTYPES.concat(RN_EVENT_SUBTYPES);

function sortSubTypesByLabel(): EventSubTypes {
  return Object.keys(SUB_TYPES)
    .sort((a, b) => SUB_TYPES[a]?.label.localeCompare(SUB_TYPES[b]?.label))
    .reduce((accumulator, key) => {
      // eslint-disable-next-line no-param-reassign
      accumulator[key] = SUB_TYPES[key];
      return accumulator;
    }, {}) as EventSubTypes;
}

const ORDERED_SUB_TYPES = sortSubTypesByLabel();

export const enum AvailabilitySubType {
  RapidAccess = 'rapid_access',
  PanelCare = 'panel_care',
  DropInClinic = 'drop_in_clinic',
  AvailableForVisits = 'scheduled_visits',
}

type AvailabilityMenuItem = {
  label: string;
  color: string;
  backgroundColor: string;
  roles: ProviderRole[];
};

type AvailabilityMenuItems = {
  [subType in AvailabilitySubType]: AvailabilityMenuItem;
};

export const AVAILABILITY_SUB_TYPE_ITEMS: AvailabilityMenuItems = {
  rapid_access: {
    label: 'Available for new patient',
    color: '#466C8A',
    backgroundColor: '#AEC5D4',
    roles: [ProviderRole.Clinician],
  },
  panel_care: {
    label: 'Available for current patient',
    color: '#696158',
    backgroundColor: '#D6CABF',
    roles: [ProviderRole.Clinician],
  },
  drop_in_clinic: {
    label: 'Available for Pop-In Clinic',
    color: '#70659e',
    backgroundColor: '#c7beed',
    roles: [
      ProviderRole.Clinician,
      ProviderRole.CareAdvocate,
      ProviderRole.RegisteredNurse,
      ProviderRole.PeerCoach,
      ProviderRole.CaseManager,
    ],
  },
  scheduled_visits: {
    label: 'Available for scheduled visits',
    color: '#77AB73',
    backgroundColor: '#C7DCC5',
    roles: [
      ProviderRole.CareAdvocate,
      ProviderRole.RegisteredNurse,
      ProviderRole.PeerCoach,
      ProviderRole.CaseManager,
    ],
  },
};

export const roleHasAvailabilitySubTypes = (role: ProviderRole) =>
  Object.values(AVAILABILITY_SUB_TYPE_ITEMS).some(subTypeItem => subTypeItem.roles.includes(role));

export const getAvailabilitySubTypesForRole = (role: ProviderRole): AvailabilityMenuItems =>
  Object.fromEntries(
    Object.entries(AVAILABILITY_SUB_TYPE_ITEMS).filter(([, item]) => item.roles.includes(role)),
  ) as AvailabilityMenuItems;

// NOTE: There are two different spellings of 'cancellation' in the following list. The values were
// originally created using the 'cancelation' which means that data in the database uses
// 'cancelation'. According to https://www.grammarly.com/blog/canceled-vs-cancelled/,
// 'cancellation' is always the correct spelling. To avoid having to do a database migration, only
// the labels have been updated to reflect the correct spelling.
export const SCHEDULE_CHANGE_REASONS = {
  provider_cancelation: { label: 'Provider Cancellation' },
  patient_cancelation: { label: 'Patient Cancellation' },
  patient_cancelation_last_minute: { label: 'Patient Cancellation - Last Minute' },
  patient_cancelation_from_app: { label: 'Patient Cancellation via App' },
  patient_missed: { label: 'Patient Missed' },
  provider_missed: { label: 'Provider Missed' },
  scheduling_error: { label: 'Scheduling Error' },
  technical_difficulties: { label: 'Technical Difficulties' },
  canceled_recurrence: { label: 'Canceled Recurrence' },
  unable_to_practice_in_state: { label: 'Unable to Practice in This State' },
  seen_in_drop_in: { label: 'Seen in Pop-In' },
  other: { label: 'Other' },
};

export const SELECTABLE_SCHEDULE_CHANGE_REASON_KEYS: Array<keyof typeof SCHEDULE_CHANGE_REASONS> = [
  'provider_cancelation',
  'patient_cancelation',
  'patient_cancelation_last_minute',
  'patient_missed',
  'provider_missed',
  'scheduling_error',
  'technical_difficulties',
  'unable_to_practice_in_state',
  'seen_in_drop_in',
  'other',
];

export const SELECTABLE_SCHEDULE_CHANGE_REASONS = pick(
  SCHEDULE_CHANGE_REASONS,
  SELECTABLE_SCHEDULE_CHANGE_REASON_KEYS,
);

export const INQUIRY_SUBTYPES = [
  'inquiry_webform',
  'inquiry_webform_call',
  'inquiry_inapp_call',
  'inquiry_inapp_enrollment',
];

export function eventTypes(): string[] {
  return Object.keys(TYPES);
}

/**
 * Gets an object of all event subtypes, even deprecated ones.
 *
 * @export
 * @returns {Object} All event subtypes.
 */
export function getAllSubTypes(): EventSubTypes {
  return ORDERED_SUB_TYPES;
}

/**
 * Gets an object of all event subtypes that have not been deprecated, keyed on subtype name.
 *
 * @export
 * @returns {Object} Non-disabled event subtypes.
 */
export function getEnabledSubTypes(): Dictionary<EventSubType> {
  return pickBy(ORDERED_SUB_TYPES, subType => !subType.disabled);
}

export function labelForEventSubType(subType: string): string {
  if (SUB_TYPES[subType]) {
    return SUB_TYPES[subType].label;
  }

  return 'Event';
}

export function labelForEventType(type: string | null, subType?: string | null): string {
  if (subType) {
    if (INTAKE_EVENT_SUBTYPES.includes(subType as SubType)) {
      return 'INTAKE';
    }
    if (FOLLOW_UP_EVENT_SUBTYPES.includes(subType as SubType)) {
      return 'FLW-UP';
    }
    if (REENGAGEMENT_EVENT_SUBTYPES.includes(subType as SubType)) {
      return 'RE-ENG';
    }

    if (SUB_TYPES[subType]) {
      return SUB_TYPES[subType].label;
    }
  }

  if (type && TYPES[type]) {
    return TYPES[type].label;
  }

  // This failsafe case should not be reached
  return 'Event';
}

export function getEventDisplayTitle(event: Event) {
  return labelForEventType(event.type, event.subType);
}

const TIMED_EVENT_TYPES = ['appointment_virtual', 'appointment_inperson', 'availability'];

// Events of these subtypes are placeholders
export const isPlaceholderEvent = event => {
  return [
    'blocked',
    'hold_new_patient',
    'hold_paneling_follow_up',
    'note',
    'staff_meeting',
  ].includes(event.subType);
};

/**
 * Returns false if the given event type is one that must have a start and end (e.g. appointments
 * and availabilities), true otherwise
 */
export function isAllDay(type: string | null): boolean {
  if (!type) {
    return true;
  }
  return !TIMED_EVENT_TYPES.includes(type);
}

export const isPast = (event: CalendarItem) => {
  const start = getStartTime(event);
  if (event.allDay) {
    return isBefore(endOfDay(start), new Date());
  } else {
    return isBefore(addMinutes(start, event.duration ?? 0), new Date());
  }
};

export const getStartTime = (event: CalendarItem): Date => {
  const startTime = parseUnknownDate(event.start);
  if (event.allDay) {
    // All-day events start at 00:00Z, which is usually "yesterday" in the US, so scootch the start
    // time forward to 00:00 local time.
    return subMilliseconds(
      startTime,
      getTimezoneOffset(Intl.DateTimeFormat().resolvedOptions().timeZone, startTime),
    );
  } else {
    return startTime;
  }
};

export const getEndTime = (event: CalendarItem) => {
  // All day events don't have durations, so just return the start time.
  if (event.allDay) {
    return getStartTime(event);
  } else if (event.duration) {
    return addMinutes(parseUnknownDate(event.start), event.duration);
  } else {
    return parseUnknownDate(event.start);
  }
};

export function scheduleSummary(item: CalendarItem) {
  if (item.allDay) {
    return formatInTimeZone(item.start, 'UTC', 'MMM d');
  } else {
    const zone = item.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;
    const start = formatInTimeZone(item.start, zone, 'MMM d, yyyy h:mma');
    const end = formatInTimeZone(getEndTime(item), zone, 'h:mma zzz');
    return `${start} - ${end}`;
  }
}

export const DISABLED_TOOLTIP =
  'Canceled, rescheduled, and completed events can no longer be edited';

export function eventIsUneditable(event: Event) {
  return !!event.status && UNEDITABLE_EVENT_STATUSES.includes(event.status);
}
