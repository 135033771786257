import type { SubType } from 'src/events/visitTypes/subType';
import { AVAILABILITY_SUB_TYPE_ITEMS } from 'src/shared/util/events';

const eventTypes = [
  'appointment_virtual',
  'availability',
  'other',
  // deprecated types, retained for backward compatibility:
  'appointment_inperson',
  'lab',
] as const;
export type EventType = (typeof eventTypes)[number];

const otherTypeSubTypes = new Set<SubType>([
  'case_manager_note',
  'case_manager_consultation',
  'inquiry_other',
  'inquiry_inapp_enrollment',
  'inquiry_webform',
  'inquiry_referrer',
  'inquiry_covid19_screening',
  'inquiry_current_patient',
  'inquiry_potential_patient',
  'rcv_setup',
  'phone_call',
  'onboarding_checklist',
  'note',
  'peer_note',
  'prescriber_note',
  'bridge_prescription_request',
  'discharge_summary',
  'registered_nurse_triage_note',
]);

export function getParentType(subType: SubType): EventType {
  if (subType === 'inperson_general') {
    return 'appointment_inperson';
  } else if (otherTypeSubTypes.has(subType)) {
    return 'other';
  } else if (Object.keys(AVAILABILITY_SUB_TYPE_ITEMS).includes(subType)) {
    return 'availability';
  }
  return 'appointment_virtual';
}
